import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import logowhite from "../assets/logowhite.png";
import s from "../assets/s.png";
import s2 from "../assets/s2.png";
import s3 from "../assets/s3.png";
import s4 from "../assets/s4.png";
import s5 from "../assets/s5.png";
import { NavLink } from "react-router-dom";

const PartnerSignup = () => {
  const [phoneState, setPhoneState] = useState({
    phoneLoading: false,
    phones: [],
    phoneErrorMessage: "",
  });

  const [isOpen2, setIsOpen2] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPhoneState({
          ...phoneState,
          phoneLoading: true,
        });

        const dataUrl = `https://restcountries.com/v3.1/all`;
        const response = await axios.get(dataUrl);
        const sorted = response.data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );

        setPhoneState({
          ...phoneState,
          phones: sorted,
          phoneLoading: false,
        });
      } catch (error) {
        setPhoneState({
          ...phoneState,
          phoneLoading: false,
          phoneErrorMessage: "Sorry something went wrong",
        });
      }
    };

    fetchData();
  }, []);

  const { phones } = phoneState;
  const [selectedPhone, setSelectedPhone] = useState({
    name: "Nigeria",
    flags: { png: "" },
    idd: { root: "+2", suffixes: ["34"] },
    area: "923768",
  });

  const searchSelectedPhone = phones.find(
    (obj) => `${obj.area}` === `${selectedPhone.area}`
  );

  const handleSelectPhone = (phone) => {
    setSelectedPhone(phone);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    website: "",
    role: "",
  });
  console.log(formData);

  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatusMessage("");
    try {
      const response = await fetch(
        "https://rivala-backend-staging-c54aeaa8e724.herokuapp.com/api/v1/partner",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: `${selectedPhone.idd.root}${selectedPhone.idd.suffixes[0]}${formData.phoneNumber}`,
            companyName: formData.companyName,
            role: formData.role,
            website: formData.website,
          }),
        }
      );
      if (response.ok) {
        setStatusMessage("You have successfully signed up for early access!");
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          companyName: "",
          website: "",
          role: "",
        });
      } else {
        const errorData = await response.json();
        setStatusMessage(
          `Error: ${
            errorData.message || "Something went wrong, please try again later."
          }`
        );
      }
    } catch (error) {
      setStatusMessage("Error: Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="px-[3%] lg:px-[5%] xl:px-[7%]   overflow-hidden bg-[#04040A] pt-[20px] pb-[150px]">
        <div className="max-w-[1480px] mx-auto h-[637px] flex items-center justify-center relative">
          <div className=" flex flex-col justify-between h-full w-full z-40">
            <div>
              <NavLink to="/">
                <img className="w-[117px]" src={logowhite} />
              </NavLink>
            </div>
            <div className="max-w-[800px] mx-auto">
              <h4 className="lg:text-[60px] text-[40px] font-[700] lg:leading-[72px] tracking-tight text-white text-center">
                Partnerships that transcends beyond borders.
              </h4>
            </div>
          </div>
          <div className="absolute top-[60px] left-[20%]">
            <img
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full "
              src={s}
            />
          </div>
          <div className="absolute top-[60px] right-[20%] ">
            <img
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full "
              src={s2}
            />
          </div>
          <div className="absolute top-[350px]  left-[5%]">
            <img
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full "
              src={s3}
            />
          </div>
          <div className="absolute top-[300px] transform -translate-x-1/2 left-1/2 ">
            <img
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full "
              src={s4}
            />
          </div>
          <div className="absolute top-[350px] right-[5%]">
            <img
              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded-full "
              src={s5}
            />
          </div>
          <div className=" w-[24px] h-[24px] rounded-full bg-[#4D008D] absolute top-[130px] transform -translate-1/2 left-1/2 "></div>
          <div className=" w-[24px] h-[24px] rounded-full bg-[#F6543E] absolute top-[200px] transform left-[30%] "></div>
          <div className=" w-[24px] h-[24px] rounded-full bg-[#FABC1C] absolute top-[200px] right-[30%] "></div>
          <div className=" w-[24px] h-[24px] rounded-full bg-[#CBFF0E] absolute top-[620px] left-[3%] "></div>
          <div className=" w-[24px] h-[24px] rounded-full bg-[#6EC1FC] absolute top-[620px] right-[3%] "></div>
        </div>
      </div>
      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:pt-[60px] pt-[80px] overflow-hidden pb-[100px]">
        <div className="max-w-[800px] mx-auto">
          <form onSubmit={handleSubmit}>
            <div className="mt-[24px] grid grid-cols-1 gap-[24px]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[32px]">
                <div>
                  <h4 className="text-[14px] font-[500] ">
                    First Name <span className="text-primary">*</span>
                  </h4>
                  <input
                    maxLength={15}
                    type="text"
                    name="firstName"
                    value={
                      formData.firstName?.charAt(0).toUpperCase() +
                      formData.firstName.slice(1)
                    }
                    required
                    onChange={handleChange}
                    className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                    placeholder="e.g John"
                  />
                </div>
                <div>
                  <h4 className="text-[14px] font-[500] ">
                    Last Name <span className="text-primary">*</span>
                  </h4>
                  <input
                    maxLength={15}
                    type="text"
                    name="lastName"
                    value={
                      formData.lastName?.charAt(0).toUpperCase() +
                      formData.lastName.slice(1)
                    }
                    required
                    onChange={handleChange}
                    className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                    placeholder="e.g Doe"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[24px] gap-x-[32px]">
                <div>
                  <h4 className="text-[14px] font-[500] ">Phone number</h4>
                  <div className="border-[1px] border-gray-200 rounded-[8px] mt-[8px] flex items-center">
                    <div className="relative  w-[200px]">
                      <div
                        className="py-[10px]  pl-[14px] rounded-[8px] w-full flex items-center cursor-pointer"
                        onClick={() => setIsOpen2(!isOpen2)}
                      >
                        <span className="mr-[10px]">
                          {searchSelectedPhone && searchSelectedPhone.flags && (
                            <img
                              className="w-[22px] h-[16px] rounded-[2px]"
                              src={searchSelectedPhone.flags.png}
                              alt=""
                            />
                          )}
                        </span>
                        <span className="text-black">
                          {searchSelectedPhone
                            ? `${selectedPhone.idd.root}${selectedPhone.idd.suffixes?.[0]}`
                            : "Select Location"}
                        </span>
                        <FiChevronDown className="absolute right-[14px] top-1/2 transform -translate-y-1/2 text-[20px] text-gray-400" />
                      </div>

                      <PhoneDropdown
                        isOpen2={isOpen2}
                        setIsOpen2={setIsOpen2}
                        options={phones}
                        onSelect={handleSelectPhone}
                        selectedOption={selectedPhone}
                      />
                    </div>
                    <input
                      maxLength={10}
                      type="tel"
                      value={formData.phoneNumber
                        .slice(0, 11)
                        .replace(/\D/g, "")}
                      onChange={handleChange}
                      name="phoneNumber"
                      className="py-[10px] px-[14px] rounded-r-[8px]   focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                      placeholder="9029439392"
                    />
                  </div>
                </div>

                <div>
                  <h4 className="text-[14px] font-[500] ">
                    Email Address <span className="text-primary">*</span>
                  </h4>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                    placeholder="e.g johndoe@example.com"
                  />
                </div>
              </div>

              <div>
                <h4 className="text-[14px] font-[500] ">Company Name</h4>
                <input
                  maxLength={15}
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                  placeholder="e.g Rivala"
                />
              </div>

              <div>
                <h4 className="text-[14px] font-[500] ">
                  Website <span className="text-primary">*</span>
                </h4>
                <input
                  maxLength={15}
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                  required
                  className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                  placeholder="e.g www.rivala.io"
                />
              </div>

              <div>
                <h4 className="text-[14px] font-[500] ">
                  Role <span className="text-primary">*</span>
                </h4>
                <input
                  maxLength={15}
                  type="text"
                  name="role"
                  value={formData.role}
                  required
                  onChange={handleChange}
                  className="py-[10px] px-[14px] rounded-[8px] border-[1px] border-gray-200 mt-[8px] focus:outline-none  focus:border-orange-300  focus:border-[1px] w-full"
                  placeholder="e.g Hiring manager"
                />
              </div>
            </div>
            <button
              className="bg-[#04040A] w-full px-[20px] py-[12px] text-[14px] md:text-[16px] font-[500] text-white rounded-[8px] hover:bg-gray-800 mt-[40px]"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Submit"}
            </button>
          </form>
          {statusMessage && (
            <p className="mt-4 text-center text-text">{statusMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
};

const PhoneDropdown = ({
  options,
  onSelect,
  selectedOption,
  isOpen2,
  setIsOpen2,
}) => {
  const handleSelectOption = (option) => {
    setIsOpen2(false);
    if (option.name.common === "United States") {
      const selectedPhone = {
        name: option.name.common,
        flags: option.flags,
        idd: {
          root: "+1",
          suffixes: [""],
        },
        area: "9372610",
      };
      onSelect(selectedPhone);
    } else {
      onSelect(option);
    }
  };

  return (
    <div className="relative">
      {isOpen2 && (
        <div className="absolute left-0 mt-2 w-[200px] bg-white border border-gray-200 rounded-md shadow-lg h-[200px] overflow-y-auto z-40">
          {options.map((item) => (
            <div
              key={uuidv4()}
              className={`flex items-center py-2 px-4 cursor-pointer hover:bg-gray-100 ${
                selectedOption && selectedOption.name.common === item.idd.root
                  ? "bg-gray-100"
                  : ""
              }`}
              onClick={() => handleSelectOption(item)}
            >
              <img
                className="w-[22px] h-[16px] rounded-[2px] mr-2"
                src={item.flags.png}
                alt=""
              />
              <span>{item.name.common}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PartnerSignup;
