import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import globe1 from "../assets/globe1.png";
import placesmall from "../assets/placesmall.png";
import Hire from "../components/Hire";
import white1 from "../assets/white1.png";
import white2 from "../assets/white2.png";
import white3 from "../assets/white3.png";
import white4 from "../assets/white4.png";
import white5 from "../assets/white5.png";
import h1 from "../assets/h1.png";
import h2 from "../assets/h2.png";
import h3 from "../assets/h3.png";
import h4 from "../assets/h4.png";
import { FiChevronLeft } from "react-icons/fi";

import Testimonial from "../components/Testimonial";

const Home = () => {
  return (
    <div>
      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:py-[60px] pt-[80px] overflow-hidden bg-[#F7EDFA]">
        <div className="max-w-[1480px] mx-auto">
          <div className="grid xl:grid-cols-2 grid-cols-1 gap-y-[100px] 2xl:gap-x-[100px] items-center">
            <div className="xl:-mt-[120px]">
              <h4 className=" md:text-[60px] text-[36px] font-[600] 2xl:font-[700] leading-[44px] md:leading-[72px]  text-[#101828] tracking-tight xl:text-left text-center">
                Scale Your Team With Our AI-Powered Talent Solution.
              </h4>
              <h4 className="text-[20px] font-[400] leading-[30px] text-text mt-[24px] font-ibm  xl:text-left text-center mx-auto xl:mx-px">
                Effortlessly build and scale your tech team with our AI-powered
                Talent Solution. Streamline recruitment, identify top talents,
                and access the creativity and diversity of Africa and Latin
                America's in-demand experts.
              </h4>
              <div className="flex flex-col md:flex-row md:justify-center xl:justify-start md:space-x-[16px] mt-[24px] space-y-[16px] md:space-y-[0px]">
                <button
                  onClick={() =>
                    window.open(
                      "https://app.rivala.io/partners?step=1",
                      "_blank"
                    )
                  }
                  className="px-[18px] py-[16px] md:px-[20px] md:py-[12px] rounded-[8px] text-[18px] md:text-[16px] font-[500] text-white bg-[#04040A] hover:bg-gray-700"
                >
                  Get Started
                </button>

                <NavLink to="/contact">
                  <button className="px-[18px] py-[16px] md:px-[20px] md:py-[12px] border-[1px] border-text rounded-[8px] text-[18px] md:text-[16px] font-[500] text-[#101828] w-full md:w-fit">
                    Contact Sales
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="xl:-mr-[280px] relative">
              <img className="xl:ml-auto  mx-auto xl:w-[85%] " src={globe1} />
              {/* <div className="absolute right-[28%] top-[330px]">
                <img className="w-1/2" src={globepic} />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  py-[60px] overflow-hidden bg-text ">
        <div className="max-w-[1480px] mx-auto  items-center flex flex-col justify-between">
          <h4 className="text-[20px] font-[500] leading-[30px] text-[#F2F2F2]">
            Brands We Work With 
          </h4>
          <div className="flex items-center space-x-[24px] md:space-x-[48px] mt-[24px] ">
            <div>
              <img className="w-[123px]" src={white1} />
            </div>
            <div>
              <img className="w-[90px]" src={white2} />
            </div>
            <div>
              <img className="w-[118px]" src={white3} />
            </div>
            <div>
              <img className="w-[126px]" src={white4} />
            </div>
            <div>
              <img className="w-[158px]" src={white5} />
            </div>
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:pt-[30px] pt-[80px] overflow-hidden">
        <div className="max-w-[1480px] mx-auto">
          <div className="py-[40px] md:my-[80px]">
            <h4 className="text-[36px] leading-[44px] font-[700] text-text mb-[60px] md:mb-[100px] md:font-[600] md:leading-[60px] md:text-[48px] tracking-tight">
              What’s in it for you
            </h4>
            <div>
              <div className=" lg:flex lg:space-x-[30px] mb-[30px] ">
                <div className="2xl:w-[65%] lg:w-[50%] rounded-[24px] px-[40px] pt-[40px] bg-[#FAFAFA] lg:h-[720px]  flex flex-col ">
                  <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                    Cut Down on Hiring Cost
                  </h4>
                  <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                    Discover top tech talents from emerging tech hubs and cut
                    your hiring expenses by over 35%
                  </h4>
                  <button
                    onClick={() =>
                      window.open(
                        "https://app.rivala.io/partners?step=1",
                        "_blank"
                      )
                    }
                    className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px]  flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px] md:w-fit"
                  >
                    Get started
                  </button>
                  <div className="mt-auto pt-[30px] ">
                    <img className=" mx-auto" src={h1} />
                  </div>
                </div>
                <div className="2xl:w-[35%] lg:w-[50%] rounded-[24px] px-[40px] pt-[40px] bg-[#FAFAFA] lg:h-[720px] flex flex-col mt-[30px] lg:mt-[0px]">
                  <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                    Talent Guarantee
                  </h4>
                  <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                    We guarantee that our platform only matches highly qualified
                    and pre-vetted tech professionals, offering a 90-day
                    guarantee to our Talent partners for every successful match.
                  </h4>
                  <button
                    onClick={() =>
                      window.open(
                        "https://app.rivala.io/partners?step=1",
                        "_blank"
                      )
                    }
                    className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px] md:w-fit flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px]"
                  >
                    Try now
                  </button>
                  <div className="mt-auto pt-[30px]">
                    <img className=" mx-auto" src={placesmall} />
                  </div>
                </div>
              </div>
              <div className=" lg:flex ">
                <div className="2xl:w-[65%] lg:w-[50%] rounded-[24px] px-[40px] pt-[40px] bg-[#FAFAFA] lg:h-[720px]  flex flex-col order-2">
                  <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                    Cut Down On Hiring Time
                  </h4>
                  <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                    Get a curated list of qualified candidates within 72 hours
                    and reduce your hiring time by over 40%.
                  </h4>
                  <button
                    onClick={() =>
                      window.open(
                        "https://app.rivala.io/partners?step=1",
                        "_blank"
                      )
                    }
                    className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px]  flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px] md:w-fit"
                  >
                    Get started
                  </button>
                  <div className="mt-auto pt-[30px] ">
                    <img className=" mx-auto" src={h3} />
                  </div>
                </div>
                <div className="2xl:w-[35%] lg:w-[50%] rounded-[24px] px-[40px] pt-[40px] bg-[#FAFAFA] lg:h-[720px] flex flex-col mt-[30px] lg:mt-[0px] order-1 lg:mr-[30px]">
                  <h4 className="text-[36px] font-[700] text-text leading-[44px]">
                    Seamlessly Scale Your Tech Team With Vetted Talents 
                  </h4>
                  <h4 className="text-[18px] font-[500] leading-[28px] text-[#56575C] mt-[16px]">
                    Grow your tech team conveniently with vetted talents that
                    align with your organizational culture and values.
                  </h4>
                  <button
                    onClick={() =>
                      window.open(
                        "https://app.rivala.io/partners?step=1",
                        "_blank"
                      )
                    }
                    className="text-white hover:bg-gray-800 px-[20px] py-[12px] rounded-[8px] md:w-fit flex justify-center bg-[#04040A] text-[14px] font-[500] mt-[24px]"
                  >
                    Try now
                  </button>
                  <div className="mt-auto pt-[30px]">
                    <img className=" mx-auto" src={h2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]  xl:py-[80px] py-[48px] bg-[#161618]">
        <div className="max-w-[1480px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-[115px] items-center">
          <div className="grid grid-cols-1 gap-[24px]">
            <h4 className="text-[48px] text-[#F2F2F2] font-[700] leading-[60px] tracking-tight">
              Transform Your Projects, One Team At A Time
            </h4>
            <h4 className="text-[#9EA0A3] text-[18px] font-[500] leading-[28px]">
              However big or small your project is, our product will ensure that
              it always has a smooth and enjoyable experience when building your
              team, planning your project, or collaborating whatever stack you
              need, Rivala has an experienced talent to match your needs
            </h4>
            <NavLink to="/partner">
              <button className="bg-[#F2F2F2] px-[20px] py-[12px] rounded-[8px] text-text font-[500] text-[16px] leading-[24px] w-fit hover:bg-gray-100">
                See how it works
              </button>
            </NavLink>
          </div>
          <div>
            <img src={h4} />
          </div>
        </div>
      </div>

      <div className="px-[3%] lg:px-[5%] xl:px-[7%]   xl:py-[80px] py-[48px] bg-[#FAFAFA]">
        <Testimonial />
      </div>

      <Hire />
    </div>
  );
};

export default Home;
