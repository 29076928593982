import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const Faqs = () => {
  const [clickedIndex, setClickedIndex] = useState({});

  const handleClick = (index) => () => {
    setClickedIndex((state) => ({
      ...state,
      [index]: !state[index],
    }));
  };

  const Dropdown = [
    {
      title: "How can my company become a partner with Rivala?",
      content:
        "Contact our partnership team through the provided form on the Partners page, and you will be guided through the process.",
    },
    {
      title: "What benefits do partners gain from collaborating with Rivala?",
      content:
        "Partners leverage our platform to efficiently source, hire, and manage remote tech talents, streamlining the expansion of their Engineering teams.",
    },
    {
      title: "What type of companies does Rivala partner with?",
      content:
        "We collaborate with a diverse range of global companies seeking highly qualified English-speaking full-time remote tech talents.",
    },
    {
      title: "Can partners choose talents based on specific criteria?",
      content:
        "Absolutely. Rivala's platform allows partners to customize their search criteria to find talents that align with their specific needs and requirements. ",
    },
    {
      title: "How does Rivala manage payroll, local compliance for partners?",
      content:
        "Rivala provides an end-to-end turnkey solution, handling all aspects of payroll, benefits, taxes, and local compliance for our partners.",
    },
    {
      title:
        "What sets Rivala apart in helping companies scale their Engineering teams quickly?",
      content:
        "Our platform offers a seamless and efficient process for companies to scale their Engineering teams by connecting them with qualified remote tech talents in the same time zone.",
    },
    {
      title:
        "Can partners access Rivala's services for a specific project or on a long-term basis?",
      content:
        "Both options are available. Partners can use Rivala's services for specific projects or engage in long-term collaborations for ongoing talent needs",
    },
  ];
  return (
    <div className="px-[3%] lg:px-[5%] xl:px-[7%] xl:py-[120px] py-[80px] ">
      <div className="max-w-[1480px] mx-auto ">
        <h4 className="text-[36px] leading-[44px] md:text-[48px] font-[700] md:leading-[60px] text-center ">
          Frequently Asked Questions
        </h4>
        <div className=" mt-[24px] xl:w-[1000px] mx-auto">
          {Dropdown.map((drop, i) => (
            <div key={i} className="border-b-[1px] border-[#E6E7E8]">
              <div
                onClick={handleClick(i)}
                className="py-[16px] flex justify-between items-center "
              >
                <h4 className="text-[16px] lg:text-[24px] cursor-pointer font-[600]">
                  {drop.title}
                </h4>
                <div
                  onClick={handleClick(i)}
                  className={`text-sm duration-300 cursor-pointer ${
                    clickedIndex[i] ? "rotate-180" : "rotate-0"
                  } `}
                >
                  <FiChevronDown className="text-[28px]" />
                </div>
              </div>
              {clickedIndex[i] ? (
                <h4 className="text-[14px] lg:text-[16px] py-[20px] pr-[38px] font-light -mt-[20px] mb-[28px]">
                  {drop.content}
                </h4>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
